<script>
</script>

<main>
	<h1>Leon</h1>
	<p>A developer who knows marketing and business.</p>
	<hr>
	<h2>Projects</h2>
	<p>Actively maintained projects.</p>
	<section>
		<aside>
			<h3><a href="https://editor.leonh.space/">Editor Leon</a></h3>
			<p><small>Personal blog - notes, experences, ideas</small></p>
		</aside>
	</section>
	<hr>
	<h2>Archived Projects</h2>
	<p>Archived. You may find something interesting.</p>
	<section>
		<aside>
			<h3><a href="https://awesomex.leonh.space/">Awesome X</a></h3>
			<p><small>A weekly news for developers</small></p>
		</aside>
		<!-- <aside>
			<h3><a href="https://codec.substack.com/">Codec</a></h3>
			<p><small>A newsletter reports awesome projects, products, articles, design works</small></p>
		</aside>
		<aside>
			<h3>INFOLINK Blog</h3>
			<p><small>As an editor and a writer</small></p>
		</aside> -->
		<!-- <aside>
			<h3><a href="https://exp2.uniuni.space/">EXP2</a></h3>
			<p><small>Personal blog - notes, experences, ideas</small></p>
		</aside>
		<aside>
			<h3><a href="https://exp.uniuni.space/">EXP</a></h3>
			<p><small>A personal blog before 2020</small></p>
		</aside>
		<aside>
			<h3><a href="https://bento.uniuni.space/">Carly's Bento</a></h3>
			<p><small>Carly's daily bento photo collection</small></p>
		</aside> -->
		<!-- <aside>
			<h3>Blogs in Tumblr</h3>
			<p><small>Personal blogs</small></p>
			<ul>
				<li><a href="https://leonsexp2.tumblr.com/">EXP2</a></li>
				<li><a href="https://tau-kuann.tumblr.com/">豆干黑豆干</a></li>
			</ul>
		</aside>
		<aside>
			<h3>Blogs in WordPress.com</h3>
			<p><small>Personal blogs</small></p>
			<ul>
				<li><a href="https://tycoder.wordpress.com/">松柏碼記</a></li>
				<li><a href="https://beangrinding.wordpress.com/">磨豆</a></li>
			</ul>
		</aside> -->
	</section>
	<hr>
	<h2>Links</h2>
	<p>My public profiles. You can contact me through these links. Followers are welcome.</p>
	<ul>
		<!-- <li><a href="https://www.plurk.com/LeonH">Plurk</a></li> -->
		<!-- <li><a href="https://mewe.com/i/%E5%9C%8B%E6%A2%81%E6%B4%AA">MeWe</a></li> -->
		<li><a href="https://twitter.com/LeonH">Twitter</a></li>
		<li><a href="https://www.linkedin.com/in/leon0824/">LinkedIn</a></li>
		<li><a href="https://www.facebook.com/Leon0824/">Facebook</a></li>
		<!-- <li><a href="https://www.facebook.com/LeonH0824/">Facebook</a></li> -->
		<li><a href="https://www.instagram.com/leon0824/">Instagram</a></li>
		<!-- <li><a href="https://www.flickr.com/photos/hleon/">Flickr</a></li> -->
		<li><a href="https://gitlab.com/leon0824">GitLab</a></li>
		<li><a href="https://github.com/Leon0824">GitHub</a></li>
	</ul>
	<p>Yeah! I have two Facebook accounts.</p>
	<hr>
	<footer>
		© All rights reserved.
	</footer>
	
</main>

<style>
</style>
